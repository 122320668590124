import * as actionTypes from "../actions/actionTypes";

const initialState = {
    walletAddress: [],
    beginMint: false,
    totalSupply: undefined,
    noMetamaskInstalled: false,
    userRejectedTransaction: false,
    pleaseConnectYourWallet: false,
    pleaseConnectToMainNet: false,
    transactionFailed: false,
    initiatingTransaction: false,
    waitingForConfirmation: false,
    moranMintedSuccessfully: false,
    limitOfTokensPerWalletReached: false,
    sideDrawerToggled:false,
}

const contractReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.STORE_USER_ADDRESS:
            return {
                ...state,
                walletAddress:action.userAddress
            }
        case actionTypes.CHANGE_MINT_STATE:
            return {
                ...state,
                beginMint:!state.beginMint
            }
            
        case actionTypes.UPDATE_TOTAL_SUPPLY:
            return {
                ...state,
                totalSupply: action.totalSupply
            }
        
        case actionTypes.USER_HASNT_INSTALLED_METAMASK:
            return {
                ...state,
                noMetamaskInstalled:!state.noMetamaskInstalled
            }
        
        case actionTypes.USER_REJECTED_TRANSACTION:
            return {
                ...state,
                userRejectedTransaction:!state.userRejectedTransaction
            }
        
        case actionTypes.PLEASE_CONNECT_YOU_WALLET:
            return {
                ...state,
                pleaseConnectYourWallet:!state.pleaseConnectYourWallet
            }
        case actionTypes.PLEASE_CONNECT_TO_MAIN_NET:
            return {
              ...state,
              pleaseConnectToMainNet: !state.pleaseConnectToMainNet,
            };
        case actionTypes.TRANSACTION_FAILED:
            return {
                ...state,
                transactionFailed:!state.transactionFailed
            }
        case actionTypes.INITIATING_TRANSACTION:
            return {
              ...state,
              initiatingTransaction:true
            };
        case actionTypes.WAITING_FOR_CONFIRMATION:
            return {
              ...state,
              waitingForConfirmation:true
            };
        case actionTypes.MORAN_MINTED_SUCCESSFULLY:
            return {
              ...state,
              moranMintedSuccessfully:true
            };
        
        case actionTypes.RESET_MINT_PROGRESS_STATUS_CALLS:
            return {
                ...state,
                initiatingTransaction: false,
                waitingForConfirmation: false,
                moranMintedSuccessfully:false
            }
        
        case actionTypes.LIMIT_OF_TOKENS_PER_WALLET_REACEHD:
            return {
                ...state,
                limitOfTokensPerWalletReached:!state.limitOfTokensPerWalletReached
            }
        
        case actionTypes.SIDE_DRAWER_TOGGLE:
            return {
              ...state,
              sideDrawerToggled:!state.sideDrawerToggled
            };
    
        default:
            break;
    }
    return state;
}
export default contractReducer;