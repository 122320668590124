import classes from "../scss/navigationBar/navBar.module.scss";
import Logo from "./logo";
import NavigationMenuItems from "./navigationMenuItems";
import NavigationMenuIcons from "./navigationMenuIcons";



const NavBar = (props) => {
    return (
      <header>
        <div className={classes.headerSection}>
          <Logo />
          <NavigationMenuItems />
          <NavigationMenuIcons walletConnect={props.connectWallet} />
        </div>
      </header>
    );
}
export default NavBar;