import classes from "../scss/navigationBar/logo.module.scss";

import * as actions from "../../store/actions/index"

import { connect } from "react-redux";

import { Link } from "react-router-dom";



const Logo = (props) => {


    const sideDrawer = () => {
      props.onToggleSideDrawer();
    };
    
    return (
      <div className={classes.logo}>
        <button onClick={sideDrawer}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="25"
            height="25"
            fill="white"
            className="bi bi-list"
            viewBox="0 0 16 16"
          >
            <path
              fillRule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"
            />
          </svg>
        </button>

        <Link to="/">
          Morans
        </Link>
      </div>
    );
}

const mapDispatchToProps = (dispatch) => {
    return {
        onToggleSideDrawer:()=>dispatch(actions.sideDrawerToggle())
    }
}

export default connect(null,mapDispatchToProps)(Logo);