import React from "react";
import { BrowserRouter } from "react-router-dom";



import './App.css';
import Central from "./central";



function App() {
  return (
    <React.Fragment>
      <BrowserRouter>
        <Central/>
      </BrowserRouter>
     
    </React.Fragment>
  );
}

export default App;
