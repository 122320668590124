// import { NavLink } from "react-router-dom";

import React from "react";

import classes from "../scss/navigationBar/navigationMenuItem.module.scss";



const NavigationMenuItem = (props) => {

  const navigateTo = (id) => {
    // props.onToggleSideDrawer();
          // console.log(props.divToNavigate);

    let offsetTiop = document.getElementById(id).offsetTop;
    window.scrollTo({
      top: offsetTiop - 100,
      behavior: "smooth",
    });
  };



  return (
    <li className={classes.naigationMenuItem}>
      {/* <NavLink
       className={isActive => isActive?classes.active:""}
        to={props.link}
      > */}

        {/* {props.children} */}
      <p onClick={() => { navigateTo(props.divToNavigate); }}>{props.children}</p>

      {/* </NavLink> */}
    </li>
  );
};

export default NavigationMenuItem;
