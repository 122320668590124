import * as actionType from "./actionTypes";

///////////////////////////////////////////////////////////////
const storeAddressAction = (address) => {
    return {
        type: actionType.STORE_USER_ADDRESS,
        userAddress:address
    }
}

export const storeAddress = (address) => {
    return dispatch => {
        dispatch(storeAddressAction(address))
    }
}
/////////////////////////////////////////////////////////////
const changeMintStateAction = () => {
    return {
        type: actionType.CHANGE_MINT_STATE
    }
}

export const changeMintState = () => {
    return dispatch => {
        dispatch(changeMintStateAction())
    }
}
/////////////////////////////////////////////////////////////
const updateTotalSupplyAction = (totalSupply) => {
    return {
        type: actionType.UPDATE_TOTAL_SUPPLY,
        totalSupply:totalSupply
    }
}

export const updateTotalSupply = (totalSupply) => {
    return dispatch => {
        dispatch(updateTotalSupplyAction(totalSupply));
    }
}
////////////////////////////////////////////////////////////
const userHasntInstalledMetamaskAction = () => {
    return {
        type: actionType.USER_HASNT_INSTALLED_METAMASK,
    }
}

export const userHasntInstalledMetamask = () => {
    return dispatch => {
        dispatch(userHasntInstalledMetamaskAction());
    }
}
///////////////////////////////////////////////////////////
const userRejectedTransactionAction = () => {
    return{
        type: actionType.USER_REJECTED_TRANSACTION
    }
}


export const userRejectedTransaction = () => {
    return dispatch => {
        dispatch(userRejectedTransactionAction());
    }
}
///////////////////////////////////////////////////////////
const pleaseConnectWalletAction = () => {
    return {
        type: actionType.PLEASE_CONNECT_YOU_WALLET
    }
}
export const pleaseConnectWallet = () => {
    return dispatch => {
        dispatch(pleaseConnectWalletAction());
    }
}
//////////////////////////////////////////////////////////
const connectToMainNetworkAction = () => {
    return {
        type:actionType.PLEASE_CONNECT_TO_MAIN_NET
    }
}

export const connectToMainNetwork = () => {
    return dispatch => {
        dispatch(connectToMainNetworkAction());
    }
}
//////////////////////////////////////////////////////////
const transactionFailedAction = () => {
    return {
        type:actionType.TRANSACTION_FAILED
    }
}

export const transactionFailed = () => {
    return dispatch => {
        dispatch(transactionFailedAction());
    }
}

///////////////////////////////////////////////////////////////

const initiatingTransactionAction = () => {
    return {
        type: actionType.INITIATING_TRANSACTION
    }
}

export const initiatingTransaction = () => {
    return dispatch => {
        dispatch(initiatingTransactionAction());
    }
}

/////////////////////////////////////////////////////////////////
const waitingForConfirmationAction = () => {
    return {
        type:actionType.WAITING_FOR_CONFIRMATION
    }
}

export const waitingForConfirmation = () => {
    return dispatch => {
        dispatch(waitingForConfirmationAction());
    }
}

/////////////////////////////////////////////////////////////////

const moranMintedSuccessfullyAction = () => {
    return {
        type:actionType.MORAN_MINTED_SUCCESSFULLY
    }   
}

export const moranMintedSuccessfully = () => {
    return dispatch => {
        dispatch(moranMintedSuccessfullyAction());
    }
}
//////////////////////////////////////////////////////////////////////

const resetMintStatusProgressCallsAction = () => {
    return {
        type:actionType.RESET_MINT_PROGRESS_STATUS_CALLS
    }
}

export const resetMintStatusProgressCalls = () => {
    return dispatch => {
        dispatch(resetMintStatusProgressCallsAction());
    }
}

//////////////////////////////////////////////////////////////////////////
const limitOfTokensPerWalletReachedAction = () => {
    return {
        type:actionType.LIMIT_OF_TOKENS_PER_WALLET_REACEHD
    }
}

export const limitOfTokensPerWalletReached = () => {
    return dispatch => {
        dispatch(limitOfTokensPerWalletReachedAction());
    }
}

////////////////////////////////////////////////////////////////////
const sideDrawerToggleAction = () => {
    return {
        type:actionType.SIDE_DRAWER_TOGGLE
    }
}

export const sideDrawerToggle = () => {
    return dispatch => {
        dispatch(sideDrawerToggleAction());
    }
}