import React,{useState,useEffect} from "react";
import { connect } from "react-redux";

import classes from "../scss/mintPortal/mintScreen.module.scss";

import Spinner from "../UI/spinner";

import * as actions from "../../store/actions/index";

const PRICE_OF_ONE_MORAN = 20;
const MAXIMUM_NUMBER_OF_MORANS_PER_WALLET = 1;
// const MAXIMUM_NUMBER_OF_MORANS_PER_WALLET = 2;


const MintScreen = (props) => {
  const { _userRejectedTransaction, onResetMintStatusProgressCalls } = props;

  useEffect(() => {
    if (_userRejectedTransaction) {
      setBeginMinting(false);
      onResetMintStatusProgressCalls();
    }
  }, [_userRejectedTransaction, onResetMintStatusProgressCalls]);

    let [numberOfTokenCount, setNumberOfTokenCount] = useState(1);
  const [beginMinting, setBeginMinting] = useState(false);

    const increaseTokenCount = () => {
        if (numberOfTokenCount === MAXIMUM_NUMBER_OF_MORANS_PER_WALLET) {
          return;
        }
        setNumberOfTokenCount(numberOfTokenCount += 1);
    }

    const decreaseTokenCount = () => {
        if (numberOfTokenCount === 1) {
            return;
        }
        setNumberOfTokenCount(numberOfTokenCount -= 1);
    }

    const getPriceOfMorans = () => {
        return numberOfTokenCount * PRICE_OF_ONE_MORAN;
    }

    const getNumberofMintTokens = () => {
        if (numberOfTokenCount === 1) {
            return "1 moran costs ";
        } else {
            return `${numberOfTokenCount} morans cost `;
        }
    }

  const beginPurchaseHandler = () => {
      setBeginMinting(true);
      props.ownersTokenNumber().then(numberOfTokensAlreadyOwned => {
        const alreadyMintedTokens=numberOfTokensAlreadyOwned.toString()
        if (
          (parseInt(alreadyMintedTokens) + numberOfTokenCount) >
          MAXIMUM_NUMBER_OF_MORANS_PER_WALLET
        ) {
          if (props._maxTokensPerWalletErr === true) {
            return;
          }
          // console.log("You have a lot of tokens in your wallet already");
          props.onSetLimitOfTokensPerWalletReached();
          props.setMaxTokenTimer();
          setBeginMinting(false);
          return;
        }
        setBeginMinting(true);
        props.mintNFT(numberOfTokenCount, getPriceOfMorans());
        
      });
      
  }
  
  const clearPurchaseHandler = () => {
    setBeginMinting(false);
    props.onResetMintStatusProgressCalls();
  }

    const clearAndExitHandler = () => {
        props.onCloseModal();
        props.removeInterval();
  }

  let spinner = null;
  if (!props._moranMintedSuccessfully) {
    spinner=<Spinner/>
  }

  let mintStatusText = null;
  if (props._initiatingTransaction) {
    mintStatusText = (
      <React.Fragment>
        <p>
          Transactions are being Initiated.
        </p>
        <p>
          This may take a while, do not refresh
          your page
        </p>
      </React.Fragment>
    );
  }
  if (props._waitingForConfirmation) {
    mintStatusText = (
      <p>We are waiting for confirmation, do not refresh your page</p>
    );
  }
  if (props._moranMintedSuccessfully) {
    mintStatusText = (
      <React.Fragment>
        <p>Your moran has been minted successfully</p>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          fill="green"
          className="bi bi-check-circle-fill"
          viewBox="0 0 16 16"
        >
          <path d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0zm-3.97-3.03a.75.75 0 0 0-1.08.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-.01-1.05z" />
        </svg>
      </React.Fragment>
    );
  }


  

  if (!beginMinting) {
    return (
      <React.Fragment>
        <div className={classes.mintScreen}>
          <p
            className={classes.totalSupply}
          >{`${props._totalSupply}/8888 already minted`}</p>


          {/* <p>{`${getNumberofMintTokens()}  ${getPriceOfMorans()} Matic excluding gas fees`}</p>
          <p>{`Public-sale, mint ${numberOfTokenCount} and get ${numberOfTokenCount} free`}</p>
          <p>{`-one wallet can mint a max of ${MAXIMUM_NUMBER_OF_MORANS_PER_WALLET} morans + ${MAXIMUM_NUMBER_OF_MORANS_PER_WALLET} free morans `}</p> */}


           <p>{`${getNumberofMintTokens()}  0 Matic excluding gas fees`}</p>
          <p>(Free-mint phase)</p> 
          <p>{`-one wallet can mint a max of ${MAXIMUM_NUMBER_OF_MORANS_PER_WALLET} moran`}</p> 
          

          <div className={classes.incOrDecNoOfMorans}>
            <button disabled={false} onClick={decreaseTokenCount}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-dash"
                viewBox="0 0 16 16"
              >
                <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
              </svg>
            </button>
            <p>{`${numberOfTokenCount}`}</p>
            <button onClick={increaseTokenCount}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-plus"
                viewBox="0 0 16 16"
              >
                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
              </svg>
            </button>
          </div>
        </div>

        <div className={classes.mintAndCancelButtons}>
          <button onClick={beginPurchaseHandler}>mint</button>
          <button onClick={clearAndExitHandler}>cancel</button>
        </div>
      </React.Fragment>
    );
  }

  let progressBarClassName = [classes.progressBar];

  if (props._initiatingTransaction) {
    progressBarClassName=[classes.progressBar, classes.progressBar25]
  }
  if (props._waitingForConfirmation) {
    progressBarClassName=[classes.progressBar, classes.progressBar50]
  }
  if (props._moranMintedSuccessfully) {
    progressBarClassName=[classes.progressBar, classes.progressBar100]
  }
    return (
      <React.Fragment>
        <div className={
          progressBarClassName.join(" ")
          // classes.progressBar
        }>
         
          <div
            className={
              beginMinting ? classes.progressStepActive : classes.progressStep
            }
            data-title="Enter amount"
          ></div>
          <div
            className={
              props._initiatingTransaction
                ? classes.progressStepActive
                : classes.progressStep
            }
            data-title="Initiating"
          ></div>
          <div
            className={
              props._waitingForConfirmation
                ? classes.progressStepActive
                : classes.progressStep
            }
            data-title="Confirmation"
          ></div>
          <div
            className={
              props._moranMintedSuccessfully
                ? classes.progressStepActive
                : classes.progressStep
            }
            data-title="Morans minted"
          ></div>
          
        </div>
        <div className={classes.mintingProgressInfo}>
          {mintStatusText}
          <div className={classes.spinner}>{spinner}</div>
        </div>
        <div className={classes.mintingProgressButton}>
          <button onClick={clearPurchaseHandler}>Close</button>
        </div>
      </React.Fragment>
    );
};

const mapStateToProps = (state) => {
    return {
      _totalSupply: state.totalSupply,
      _initiatingTransaction: state.initiatingTransaction,
      _waitingForConfirmation: state.waitingForConfirmation,
      _moranMintedSuccessfully: state.moranMintedSuccessfully,
      _maxTokensPerWalletErr: state.limitOfTokensPerWalletReached,
      _userRejectedTransaction: state.userRejectedTransaction,
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
      onCloseModal: () => dispatch(actions.changeMintState()),
      onResetMintStatusProgressCalls: () => dispatch(actions.resetMintStatusProgressCalls()),
      onSetLimitOfTokensPerWalletReached:() =>dispatch(actions.limitOfTokensPerWalletReached()),
        
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(MintScreen);
