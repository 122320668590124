import React,{useEffect} from "react";


import classes from "../scss/UI/modal.module.scss";

import Backdrop from "./backdrop";


const Modal = (props) => {
    useEffect(() => {
        if (props.show) {
            // document.body.style.boxSizing = "border-box";
            document.body.style.overflow = "hidden";/////causes some weird flex of the page when toggle by the button
            // document.getElementById("root").style.overflow = "hidden";
            // document.body.style.marginRight="17px"////review this
        }
        return () => {
            document.body.style.overflow = "unset";
        }
  },[props.show])

    return (
        <React.Fragment>
            <Backdrop reveal={props.show}/>
        <div
          className={classes.modal}
          style={{
            opacity: props.show ? "1" : "0",
            transform: props.show ? "translateY(0)" : "translateY(-100vh)",
          }}
        >
          {props.children}
        </div>
      </React.Fragment>
    );
    
}
export default Modal;