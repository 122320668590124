import React from "react";
// import NavBar from "../navigationBar/navBar";

import classes from "../scss/noNavigationRoute/noNavigationRoute.module.scss";

const NoRoutePage = () => {
    return (
      <React.Fragment>
        {/* <NavBar connectWallet={this.connectWalletHandler} /> */}
        {/* <NavBar/> */}
        <p className={classes.noNavigation}
         
        >
          An opensea link will be provided close to the mint date.{" "}
          <a
            href="/"
            style={{
              textDecoration: "none",
              color: "#E66294",
            }}
          >
            Click here
          </a>{" "}
          to head back home....
        </p>
      </React.Fragment>
    );
}

export default NoRoutePage;