import classes from "../scss/meetTheTeam/meetTheTeam.module.scss";

const MeetTheTeam = (props) => {
  return (
    <div className={classes.meetTheTeam}>
      <div className={classes.profilePic}>
        <div
          className={classes.imageDiv}
          style={{
            background: `url(${props.imageURL}) center center no-repeat`,
            backgroundSize: "cover",
          }}
        ></div>
      </div>
      {/* <div>
              
          </div> */}
      <p>
        <a href={props.socialMedia} target="_blank" rel="noreferrer">
          {props.name}
        </a>
      </p>
      <p>{props.children}</p>
    </div>
  );
};
export default MeetTheTeam;
