import { ethers } from "ethers";
import React, { Component } from "react";
import { connect } from "react-redux";
import  axios  from "axios";
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";

import Token from "../contracts/moran.json";

import NavBar from "../components/navigationBar/navBar";
import HomeBanner from "../components/landing/homePageBanner";
import ImageGrid from "../components/imageGrid/imageGrid";
import About from "../components/imageGrid/about";
import Footer from "../components/footer/footer";
import MeetTheTeamAssembly from "../components/meetTheTeam/meetTheTeamAssembly";
import Modal from "../components/UI/modal";
import MintScreen from "../components/mintingPortal/mintScreen";
import MetamaskNotInstalledErrorModal from "../components/UI/metamaskNotInstalledErrorModal";
import PleaseConnectWalletModal from "../components/UI/pleaseConnectWalletModal";
import RejectedMintErrorModal from "../components/UI/rejectedMintErrorModal";
import PleaseConnectToMainNet from "../components/UI/pleaseConnectToMainNetModal";
import TransactionFailedError from "../components/UI/transactionFailedErrorModal";
import LimitOfTokenNumberErrorModal from "../components/UI/limitOfTokensPerWalletError";
import SideBar from "../components/sideBar/sideBar";
// import RoadMap from "../components/roadmap/roadMap";
import FAQAssembly from "../components/FAQ/faqAssembly";

import classes from "../components/scss/containers/moranHome.module.scss";





import * as actions from "../store/actions/index";

const USER_REJECTED_TRANSACTION = 4001;
const ETHEREUM_MAINNET_CHIANID = 137;
const MINT_CAUTION = "Your wallet cannot hold more than 1 free moran";
// const MINT_CAUTION = "Your wallet cannot hold more than 4 morans";

class MoranHome extends Component {

  /**
   *Handler connects users wallet upon a button click
   *We check if a provider has been injected to our browser and only then do we connect the users account
   *
   * @memberof MoranHome
   *
   */
  connectWalletHandler = () => {

    if (window.ethereum === undefined) {
      console.log("Please Install Metamask");
      if (this.props.noMetaMaskInstalled === true) {
        // console.log("we caught you in your tracks");
        return;
      }

      this.props.onUserHasntInstalledMetamask();
      this._setNoMetamaskInstalledErrorModal();
      //Handle users who do not have Metamask installed i.e direct them to the metamask site
    } else {
      this.connectWallet();
    }
  };

  /**
   *component did mount runs everyTime the component is mounted to the screen. In here we wanna check if the dapp was previously already connected
   *to metamask. This is done using an "eth_accounts"  RPC request to metamask. To it we pass a callback function that handles any new account( if detected)
   *
   * In here we also listen for any accountsChanged event from metamask, this ensures that if the user changes an account without
   * a page refresh we will still store the new account address in our state.
   *
   * We also handle any chain change, ie if for example the user changes from the main ethereum network to Rinkeby test network or any other network.
   * We do this by listening to the "chainChange" event, it gives us a chainId which we can use to detect the network we are in.
   * to check out various chainIds for various networks: https://chainlist.org/
   * most common chainIds: etheriumMainnet=1, localHost 8484= 1337 etc
   *
   */
  componentDidMount = () => {//consider looking at window.ethereum.on("chainCHanged")
    const firebaseConfig = {
      apiKey: "AIzaSyCd9Q8kkHakFDVk-KH7qwUVvxrApKCGJ7M",
      authDomain: "moranwebsite-2438b.firebaseapp.com",
      projectId: "moranwebsite-2438b",
      storageBucket: "moranwebsite-2438b.appspot.com",
      messagingSenderId: "902847987536",
      appId: "1:902847987536:web:f1e0f80380f59d30a57655",
      measurementId: "G-239H60NV6C",
    };

    // Initialize Firebase
    const app = initializeApp(firebaseConfig);
   this.analytics = getAnalytics(app);
    // this.analytics.logEvent("first-visit");
    logEvent(this.analytics, "first-visit");


    if (window.ethereum === undefined) {
      return;
    }

    
    window.ethereum
      .request({ method: "eth_accounts" })
      .then((newAccount) => {
        // const account=newAccount[0]
        this.props.onStoreAddress(newAccount); /////Remember to remove this updateUserAddress since we are now managing state with redux
      })
      .catch((err) => {
        console.log(err);
      });

    window.ethereum.on("accountsChanged", (newAccount) => {
      if (newAccount === undefined) {
        // console.log("handle if user disconnects wallet from our website");
      }

      this.props.onStoreAddress(newAccount); ///line connects to redux to store our newaccountaddress
    });

    window.ethereum.on("chainChanged", (chainId) => {
      if (parseInt(chainId, 16) !== ETHEREUM_MAINNET_CHIANID) {
        window.location.reload();
      }
    });
  };

  connectWallet = async () => {
    const [accounts] = await window.ethereum
      .request({ method: "eth_requestAccounts" })
      .catch((err) => {
        // console.log(err);
        if (err.code === USER_REJECTED_TRANSACTION) {
          /////set an error state that inform the user that he/she cancelled the transaction
          // console.log("user rejected connecting wallet");
        }
      });

    this.props.onStoreAddress(accounts); ///sends an address to our redux store
    // console.log("We are trying to oconnect");
  };

  mintAnNFTHandler = async () => {
    if (this.props.addr > 0) {

      let chainId = await window.ethereum.request({ method: "eth_chainId" });
      chainId = parseInt(chainId, 16);

      if (chainId !== ETHEREUM_MAINNET_CHIANID) {
        if (this.props.pleaseConnectToMainNet === true) {
          // console.log("we cought you in your tracks");
          return;
        }

        this.props.onPleaseConnectToMainNet();
        this._setConnectToMainNetErrorModal();
        ///set an error state that prompts the user to connect to the main Network
        return;
      }

      this.initializeContract();

      this.props.onBeginMint(); ///sets a begin mint state in our redux store to true allowing the mintScreenmodal to be shown;
    } else {
      ////send an error state that requests the user to connect his/her wallet
      if (this.props.pleaseConnectWallet === true) {
        ///this if function checks if the error state is true i.e the modal is being shown..this prevents teh user from clicking the button many times to show the error modal
        return;
      }
      this.props.onPleaseConnectWallet(); ///sends an action to the store to change a state to true
      this._setConnectWalletErrorModal(); ///A timer to show the error modal then clear the timeout after the durationn ends
      return;
    }

    this._startPollingData(); ///A timer that gives us the amount of tokens that have already been minted
  };

  initializeContract = () => {
    this.provider = new ethers.providers.Web3Provider(window.ethereum);
    this.contract = new ethers.Contract(
      Token.address,
      Token.abi,
      this.provider.getSigner(0)
    );
  };


  checkTokensOwnedByAddress = async () => {
    const numberOfTokensOwned = await this.contract.balanceOf(this.props.addr.toString());
    return numberOfTokensOwned;
  }

  fetchFreeMintHexProof = async () => {
    const address = this.props.addr
    const realAddress = String(address[0]);
    const data = { address: realAddress };
    // console.log(address[0].toString());
    const hexProof = await axios({
      method: "POST",
      url: "http://140.82.10.154:80/free-mint/hex-proof", /////the path
      // headers: { "content-type": "appliaction/json" },
      data: data,
    });

    return hexProof.data.hexproof;
  }

  
///PublicMInt
  NFTPublicMint = async (numberToMint, amount) => {

    let chainId = await window.ethereum.request({ method: "eth_chainId" });

    // console.log(chainId);

    chainId = parseInt(chainId, 16);

    if (chainId !== ETHEREUM_MAINNET_CHIANID) {
      if (this.props.pleaseConnectToMainNet === true) {
        // console.log("we cought you in your tracks");
        return;
      }

      this.props.onPleaseConnectToMainNet();
      this._setConnectToMainNetErrorModal();
      ///set an error state that prompts the user to connect to the main Network
      return;
    }

    ///////////////////////////////////////initiating
    
    this.props.onInitiateTransaction();

    try {
      const options = ethers.utils.parseEther(amount.toString());
      const transaction = await this.contract.publicMint(
        numberToMint,
        {
          value: options,
        }
      );
      // console.log("the user ALireject");///////////////////////////waiting Confirmation
      this.props.onWaitingForConfirmation();
      const receipt =await transaction.wait(1);

      if (receipt.status === 0) {
        //set an error state that informs the user that the transaction did not take place
        console.log("there was an error when processing the transaction");
        this.props.onTransactionFailed();
        this._setTransactionFailedErrorModal();
        return;
      }

      /////////////////////////////////////////////We minted it
      this.props.onMoranMintedSuccessfully();
       logEvent(this.analytics, "moran-public-mint",{walletAddress:this.props.addr});

    } catch (error) {
      if (error.code === USER_REJECTED_TRANSACTION) {
        console.log("User rejected transaction");

        this.props.onUserRejectedTransaction(); //set an error state that informs the user that he/she cancelled the transaction
        this._setRejectedMintErrorModal(); //sets a timer that removes the error modal after a set time
        return;
      }
    } finally {
      this.updateTotalSupply();
    }

   
  };
  
  /////NFT free Mint
  NFTFreeMint = async (numberToMint, amount) => {
    let chainId = await window.ethereum.request({ method: "eth_chainId" });
    chainId = parseInt(chainId, 16);

    if (chainId !== ETHEREUM_MAINNET_CHIANID) {
      if (this.props.pleaseConnectToMainNet === true) {
        // console.log("we cought you in your tracks");
        return;
      }
      this.props.onPleaseConnectToMainNet();
      this._setConnectToMainNetErrorModal();
      ///set an error state that prompts the user to connect to the main Network
      return;
    }

    ///////////////////////////////////////initiating
    
    this.props.onInitiateTransaction();

    try {
      const transaction = await this.contract.freeMint();
      // console.log("the user ALireject");///////////////////////////waiting Confirmation
      this.props.onWaitingForConfirmation();
      const receipt =await transaction.wait(1);

      if (receipt.status === 0) {
        //set an error state that informs the user that the transaction did not take place
        this.props.onTransactionFailed();
        this._setTransactionFailedErrorModal();
        return;
      }

      /////////////////////////////////////////////We minted it
      this.props.onMoranMintedSuccessfully();
       logEvent(this.analytics, "NFTFreeMint", {
         walletAddress: this.props.addr,
       });

    } catch (error) {
      if (error.code === USER_REJECTED_TRANSACTION) {
        console.log("User rejected transaction");

        this.props.onUserRejectedTransaction(); //set an error state that informs the user that he/she cancelled the transaction
        this._setRejectedMintErrorModal(); //sets a timer that removes the error modal after a set time
        return;
      }
    } finally {
      this.updateTotalSupply();
    } 
  };


  updateTotalSupply = async () => {
    const totalMinted = await this.contract.totalSupply();
    const _totalMinted = totalMinted.toString();
    this.props.onUpdateTotalSupply(_totalMinted);
  };

  _startPollingData = () => {
    this.timeInterval = setInterval(() => {
      this.updateTotalSupply();

      // console.log("We are now polling data");
    }, 2000);

    this.updateTotalSupply();
  };

  _stopPollingData = () => {
    clearInterval(this.timeInterval);
    this.timeInterval = undefined;
  };

  ////MetaMask not installed Error Modal
  _setNoMetamaskInstalledErrorModal = () => {
    this.noMetamaskInstallerTimer = setTimeout(() => {
      this.props.onUserHasntInstalledMetamask();
      this._clearMetamskTimeOut();
    }, 3000);
  };

  _clearMetamskTimeOut = () => {
    clearTimeout(this.noMetamaskInstallerTimer);
    this.noMetamaskInstallerTimer = undefined;
  };

  _metamaskClearTimeoutButton = () => {
    this.props.onUserHasntInstalledMetamask();
    this._clearMetamskTimeOut();
  };

  ////Mint Rejected Modal Config
  _setRejectedMintErrorModal = async () => {
    this.rejectedMintTimer = await setTimeout(() => {
      this.props.onUserRejectedTransaction();
      this._clearRejectedMintTimeOut();
    }, 3000);
  };

  _clearRejectedMintTimeOut = () => {
    clearTimeout(this.rejectedMintTimer);
    this.rejectedMintTimer = undefined;
  };

  _RejectedMintClearTimeoutButton = () => {
    this.props.onUserRejectedTransaction();
    this._clearRejectedMintTimeOut();
  };

  /////PLease Connect your wallet modal

  _setConnectWalletErrorModal = () => {
    this.connectWalletTimer = setTimeout(() => {
      this.props.onPleaseConnectWallet();
      this._clearConnectWalletTimeOut();
    }, 3000);
  };

  _clearConnectWalletTimeOut = () => {
    clearTimeout(this.connectWalletTimer);
    this.connectWalletTimer = undefined;
  };

  _ConnectWalletTimeoutButton = () => {
    this.props.onPleaseConnectWallet();
    this._clearConnectWalletTimeOut();
  };

  ////Please connect to the mainNetwork

  _setConnectToMainNetErrorModal = () => {
    this.connectToMainNetTimer = setTimeout(() => {
      this.props.onPleaseConnectToMainNet();
      this._clearConnectToMainNetTimeOut();
    }, 3000);
  };

  _clearConnectToMainNetTimeOut = () => {
    clearTimeout(this.connectToMainNetTimer);
    this.connectToMainNetTimer = undefined;
  };

  _connectToMainNetTimeoutButton = () => {
    this.props.onPleaseConnectToMainNet();
    this._clearConnectToMainNetTimeOut();
  };

  /////////////////////Transaction Failed Error  Modal

  _setTransactionFailedErrorModal = () => {
    this.transactionFailedTimer = setTimeout(() => {
      this.props.onTransactionFailed();
      this._clearTransactionFailedTimeOut();
    }, 600000);
  };

  _clearTransactionFailedTimeOut = () => {
    clearTimeout(this.transactionFailedTimer);
    this.transactionFailedTimer = undefined;
  };

  _transactionFailedTimeoutButton = () => {
    this.props.onTransactionFailed();
    this._clearTransactionFailedTimeOut();
  };

  /////////////////////Max tokens per wallet Error modal

  _setMaxTokensPerWalletErrorModal = () => {
    this.maxTokensPerWalletTimer = setTimeout(() => {
      this.props.onLimitOfTokensPerWalletReached();
      this._clearMaxTokensPerWalletimeOut();
    }, 3000);
  };

  _clearMaxTokensPerWalletimeOut = () => {
    clearTimeout(this.maxTokensPerWalletTimer);
    this.maxTokensPerWalletTimer = undefined;
  };

  _maxTokensPerWalletTimeoutButton = () => {
    this.props.onLimitOfTokensPerWalletReached();
    this._clearMaxTokensPerWalletimeOut();
  };

  render() {
    let showMintModal = null;
    let pleaseInstallMetamaskErrorModal = null;
    let modalIfUserRejectedTransaction = null;
    let pleaseConnectWalletModal = null;
    let pleaseConnectToMainNet = null;
    let transactionFailed = null;
    let limitOfTokenPerWalletError = null;

    if (this.props.beginMinting) {
      showMintModal = (
        <div className={classes.mintScreen}>
          <MintScreen
            setMaxTokenTimer={this._setMaxTokensPerWalletErrorModal}
            ownersTokenNumber={this.checkTokensOwnedByAddress}
            mintNFT={this.NFTFreeMint}
            removeInterval={this._stopPollingData}
          />
        </div>
      );
    }

    if (this.props.noMetaMaskInstalled) {
      pleaseInstallMetamaskErrorModal = (
        <MetamaskNotInstalledErrorModal
          show={this.props.noMetaMaskInstalled}
          closeErrorModal={this._metamaskClearTimeoutButton}
        >
          <p>
            Please install{" "}
            <a href="https://metamask.io/" target="_blank" rel="noreferrer">
              Metamask,click here
            </a>
          </p>
        </MetamaskNotInstalledErrorModal>
      );
    }

    if (this.props.transactionRejected) {
      modalIfUserRejectedTransaction = (
        <RejectedMintErrorModal
          show={this.props.transactionRejected}
          closeErrorModal={this._RejectedMintClearTimeoutButton}
        >
          <p>The Transaction was rejected</p>
        </RejectedMintErrorModal>
      );
    }

    if (this.props.pleaseConnectWallet) {
      pleaseConnectWalletModal = (
        <PleaseConnectWalletModal
          show={this.props.pleaseConnectWallet}
          closeErrorModal={this._ConnectWalletTimeoutButton}
        >
          <p>Please connect your wallet</p>
        </PleaseConnectWalletModal>
      );
    }

    if (this.props.pleaseConnectToMainNet) {
      pleaseConnectToMainNet = (
        <PleaseConnectToMainNet
          show={this.props.pleaseConnectToMainNet}
          closeErrorModal={this._connectToMainNetTimeoutButton}
        >
          <p>
            Please connect your wallet to <br />
            <a href="https://coinmarketcap.com/alexandria/article/connect-metamask-to-polygon-network" target="_blank" rel="noreferrer">
              Matic mainnet
            </a>
          </p>
        </PleaseConnectToMainNet>
      );
    }

    if (this.props.transactionFailedErr) {
      transactionFailed = (
        <TransactionFailedError
          show={this.props.transactionFailedErr}
          closeErrorModal={this._transactionFailedTimeoutButton}
        >
          <p>
            The transaction could not go through
            <br />{" "}
            <a
              href="https://info.etherscan.com/reason-for-failed-transaction/"
              target="_blank"
              rel="noreferrer"
            >
              click here
            </a>{" "}
            for reasons as to why this may have happened.
          </p>
        </TransactionFailedError>
      );
    }

    if (this.props.limitOfTokensPerWalletReached) {
      limitOfTokenPerWalletError = (
        <LimitOfTokenNumberErrorModal
          show={this.props.limitOfTokensPerWalletReached}
          closeErrorModal={this._maxTokensPerWalletTimeoutButton}
        >
          <p>{MINT_CAUTION}</p>
        </LimitOfTokenNumberErrorModal>
      );
    }
      return (
        <React.Fragment>
          {pleaseConnectWalletModal}
          {pleaseInstallMetamaskErrorModal}
          {pleaseConnectToMainNet}
          {modalIfUserRejectedTransaction}
          {transactionFailed}
          {limitOfTokenPerWalletError}

          {this.props.sideBarToggle ? <SideBar /> : null}
          <NavBar connectWallet={this.connectWalletHandler} />

          <HomeBanner mintAToken={this.mintAnNFTHandler} />

          <Modal show={this.props.beginMinting}>{showMintModal}</Modal>

          <div className={classes.wrapper}>
            <div className={classes.container}>
              <ImageGrid />
              <About />
            </div>
          </div>
          {/* <div className={classes.roadMap}>
            <RoadMap />
          </div> */}

          <div className={classes.joinTheMoranCommunity}>
            <p>We do not have a discord</p>
            <p>We are not plannig to make a game either... Not now...</p>
            {/* <p>so don't go looking</p> */}
            {/* <p>So dont go looking</p> */}
            {/* <button>
              <a
                href="https://discord.gg/yC5Kser3Wb"
                target="_blank"
                rel="noreferrer"
              >
                Join our discord
              </a>
            </button> */}
          </div>
          <div className={classes.meetTheTeamWrapper}>
            <h2 style={{ textAlign: "center", marginBottom: "25px" }}>
              The Team
            </h2>
            <div className={classes.meetTheTeamSection} id={"meet_the_team"}>
              <MeetTheTeamAssembly />
            </div>
          </div>

          <div className={classes.frequentlyAskedQuestions} id={"FAQ"}>
            <h1>FAQ</h1>
            <FAQAssembly />
          </div>
          <footer className={classes.footerSection}>
            <Footer />
            <p className={classes.cc}>
              @2022 Morans, all rights reserved.
            </p>
          </footer>
        </React.Fragment>
      );
  }
}


const mapStateToProps = (state) => {
  return {
    addr: state.walletAddress,
    beginMinting: state.beginMint,
    noMetaMaskInstalled: state.noMetamaskInstalled,
    transactionRejected: state.userRejectedTransaction,
    pleaseConnectWallet: state.pleaseConnectYourWallet,
    pleaseConnectToMainNet: state.pleaseConnectToMainNet,
    transactionFailedErr: state.transactionFailed,
    limitOfTokensPerWalletReached: state.limitOfTokensPerWalletReached,
    sideBarToggle: state.sideDrawerToggled,
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    onStoreAddress: (address) => dispatch(actions.storeAddress(address)),
    onBeginMint: () => dispatch(actions.changeMintState()),
    onUpdateTotalSupply: (_totalSupply) => dispatch(actions.updateTotalSupply(_totalSupply)),
    onUserHasntInstalledMetamask: () => dispatch(actions.userHasntInstalledMetamask()),
    onUserRejectedTransaction: () => dispatch(actions.userRejectedTransaction()),
    onPleaseConnectWallet: () => dispatch(actions.pleaseConnectWallet()),
    onPleaseConnectToMainNet: () => dispatch(actions.connectToMainNetwork()),
    onTransactionFailed: () => dispatch(actions.transactionFailed()),
    onInitiateTransaction: () => dispatch(actions.initiatingTransaction()),
    onWaitingForConfirmation: () => dispatch(actions.waitingForConfirmation()),
    onMoranMintedSuccessfully: () => dispatch(actions.moranMintedSuccessfully()),
    onLimitOfTokensPerWalletReached:()=>dispatch(actions.limitOfTokensPerWalletReached()),
  };
}



export default connect(mapStateToProps,mapDispatchToProps)(MoranHome);
