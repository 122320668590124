import classes from "../scss/landing/homeBanner.module.scss";
import moranBanner from "../../assets/images/bannerImage.png";
// import Button from "../shared/component/button";



const HomeBanner = (props) => {
  return (
    <div className={classes.homeBanner}>
      <div className={classes.homeText}>
        <p>
          Unique collectible tokens <br />
          {/* <span>that dwell</span> */}
          {/* <br /> */}
          <span>on the Polygon blockchain</span>
        </p>
        <button onClick={props.mintAToken}>
          Mint a moran
        </button>

        {/* <button>
          Mint Coming Soon
        </button> */}
   
        {/* <p className={classes.mintDisplay}>Mint Coming Soon</p> */}
      </div>
      <div
        className={classes.image}
        style={{
          background: `url(${moranBanner}) center center no-repeat`,
          backgroundSize: "cover",
        }}
      ></div>
    </div>
  );
};


export default HomeBanner;
