export const STORE_USER_ADDRESS = "STORE_USER_ADDRESS";
export const CHANGE_MINT_STATE = "CHANGE_MINT_STATE";
export const UPDATE_TOTAL_SUPPLY = "UPDATE_TOTAL_SUPPLY";
export const USER_HASNT_INSTALLED_METAMASK = "USER_HASNT_INSTALLED_METAMASK";
export const USER_REJECTED_TRANSACTION = "USER_REJECTED_TRANSACTION"; 
export const PLEASE_CONNECT_YOU_WALLET = "PLEASE_CONNECT_YOU_WALLET";
export const PLEASE_CONNECT_TO_MAIN_NET = "PLEASE_CONNECT_TO_MAIN_NET";
export const TRANSACTION_FAILED = "TRANSACTION_FAILED";
export const INITIATING_TRANSACTION = "INITIATING_TRANSACTION";
export const WAITING_FOR_CONFIRMATION = "WAITING_FOR_CONFIRMATION";
export const MORAN_MINTED_SUCCESSFULLY = "MORAN_MINTED_SUCCESSFULLY";
export const RESET_MINT_PROGRESS_STATUS_CALLS = "RESET_MINT_PROGRESS_STATUS_CALLS";
export const LIMIT_OF_TOKENS_PER_WALLET_REACEHD = "LIMIT_OF_TOKENS_PER_WALLET_REACEHD";
export const SIDE_DRAWER_TOGGLE = "SIDE_DRAWER_TOGGLE";