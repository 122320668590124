import classes from "../scss/navigationBar/navigationMenuIcon.module.scss";
// import { Link } from "react-router-dom";

const NavigationMenuIcon = (props) => {
  
    return (
      <li className={classes.navigationMenuIcon}>
        <a
          href={props.link}
          target={props.target}
          rel="noreferrer"
        >
          {props.children}
        </a>
      </li>
    );
}
export default NavigationMenuIcon;