import React from "react";

import MeetTheTeam from "./meetTheTeam";

import profileImageLewis from "../../assets/images/65copy.png";
import profileImageSmith from "../../assets/images/167profilePic.png";
import profileImageKaroki from "../../assets/images/139profilePic.png";

const MeetTheTeamAssembly = () => {
    return (
      <React.Fragment>
        <MeetTheTeam
          imageURL={profileImageLewis}
          name="@otieno_otieno"
          socialMedia="https://twitter.com/lewynation29"
        >
          Web3 enthusiast and an artist. Always silent but I
          think my powers are revealed in my bathroom. Board member.
        </MeetTheTeam>
        <MeetTheTeam
          imageURL={profileImageSmith}
          name="@thismaker"
          socialMedia="https://twitter.com/thismaker47"
        >
          ToothPaste and orange Juice. I was born to develop for microsoft,
          I can feel it in my veeeeiins.
        </MeetTheTeam>
        <MeetTheTeam
          imageURL={profileImageKaroki}
          name="@phytenser"
          socialMedia="https://twitter.com/PhyTensor"
        >
          Theoretical Physicist. Linux. Quantum Computing. Big terms huhh?
          Game developer specializing mostly in godot
        </MeetTheTeam>
      </React.Fragment>
    );
}

export default MeetTheTeamAssembly;