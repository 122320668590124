import MoranHome from "./containers/moranHome";

import classes from "./components/scss/central.module.scss";
import NoRoutePage from "./components/noRoutePage/noRoutePage";


import { Route,Routes } from "react-router-dom";

const Central = () => {
    return (
      <div className={classes.central}>
        <Routes>
          <Route path="/" element={<MoranHome />} />
          <Route path="/openSea" element={<NoRoutePage/>}/>
          <Route path="/*" element={<MoranHome/>}/>
        </Routes>
      </div>
    );
}

export default Central;