import classes from "../scss/ImageGrid/imageGrid.module.scss";
import moran29 from "../../assets/images/29copy.png";
import moran11 from "../../assets/images/11copy.png";
import moran196 from "../../assets/images/196copy.png";
// import moran1 from "../../assets/images/1copy.png";
import moran1 from "../../assets/images/femaleMoran.png";

const ImageGrid = () => {
  return (
    <div className={classes.imageGrid}>
      <div
        style={{
          background: `url(${moran29}) center center no-repeat`,
          backgroundSize: "cover",
        }}
      ></div>
      <div
        style={{
          background: `url(${moran1}) center center no-repeat`,
          backgroundSize: "cover",
        }}
      ></div>
      <div
        style={{
          background: `url(${moran196}) center center no-repeat`,
          backgroundSize: "cover",
        }}
      ></div>
      <div
        style={{
          background: `url(${moran11}) center center no-repeat`,
          backgroundSize: "cover",
        }}
      ></div>
    </div>
  );
};
export default ImageGrid;
