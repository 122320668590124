import classes from "../scss/FAQ/faq.module.scss";

const FAQ = (props) => {
    return (
        <div className={classes.frequentlyAskedQuestions}>
            <p className={classes.faqTitle}>{props.title}</p>
            { props.children}
        </div>
    );
}

export default FAQ;