import React from "react";

import classes from "../scss/FAQ/faqAssembly.module.scss";

import FAQ from "./faq";

const FAQAssembly=()=>{
    return (
      <React.Fragment>
        <FAQ title="What is an NFT?">
          <p className={classes.FAQDescription}>
            🤦‍♂️Don't be lazy, go watch this{" "}
            <a
              href="https://www.youtube.com/watch?v=Oz9zw7-_vhM"
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "none",
                color: "#E66294",
              }}
            >
              video
            </a>{" "}
          </p>
        </FAQ>
        <FAQ title="How many morans are available?">
          <p className={classes.FAQDescription}>
            There will only ever be 8,888 morans in existance
          </p>
        </FAQ>
        <FAQ title="When will i be able to mint?">
          <p className={classes.FAQDescription}>Free mint currently active</p>
        </FAQ>
        <FAQ title="How much will it cost to mint a moran?">
          <p className={classes.FAQDescription}>-First 407 Morans: FreeMint</p>
          <p className={classes.FAQDescription}>-All Other Morans: Mint 1 moran to get 1 free Moran @ 20 Matic</p>
          <p className={classes.FAQDescription}>-Last 200 Morans: Reserved by the creators as a libation to their ancestors</p>
        </FAQ>
        {/* <FAQ title="How many morans can i mint?">
          <p className={classes.FAQDescription}>It is something special</p>
        </FAQ> */}
        <FAQ title="How can i mint a moran?">
          <p className={classes.FAQDescription}>
            Connect your metamask wallet to our website(browser), choose the amount of morans you wish to mint.
            View you Moran in OpenSea
          </p>
        </FAQ>
        {/* <FAQ title="Where can i look at my moran?">
          <p className={classes.FAQDescription}>
            Once minted your moran can be viewed on OpenSea. Just connect your
            wallet and there will be a moran in your profile(if a mint was
            susccessful). We are however working on a portal on our website that
            owners will be able to use to interract with their minted morans.
          </p>
        </FAQ> */}
      </React.Fragment>
    );
}

export default FAQAssembly;