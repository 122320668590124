// import NavigationMenuItems from "../navigationBar/navigationMenuItems";
import React,{useEffect} from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";

import * as actions from "../../store/actions/index";

import classes from "../scss/sideBar/sideBar.module.scss";

// import NavigationMenuItem from "../navigationBar/navigationMenuItem";
import NavigationMenuIcon from "../navigationBar/navigationMenuIcon";

// import NavigationMenuItems from "../navigationBar/navigationMenuItems";

const SideBar = (props) => {
    useEffect(() => {
        document.body.style.overflow = "hidden";

        return () => {
            document.body.style.overflow = "unset";
        }
    },[])

    const sideDrawer = () => {
    props.onToggleSideDrawer()
  }
  
  const navigateTo = (id) => {
    props.onToggleSideDrawer();
    let offsetTop = document.getElementById(id).offsetTop;
    window.scrollTo({
      top: offsetTop - 100,
      behavior: "smooth"
    });


  }

    return (
      <React.Fragment>
        <div className={classes.sideBar}>
          <button onClick={sideDrawer}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              fill="currentColor"
              className="bi bi-x-lg"
              viewBox="0 0 16 16"
            >
              <path
                fillRule="evenodd"
                d="M13.854 2.146a.5.5 0 0 1 0 .708l-11 11a.5.5 0 0 1-.708-.708l11-11a.5.5 0 0 1 .708 0Z"
              />
              <path
                fillRule="evenodd"
                d="M2.146 2.146a.5.5 0 0 0 0 .708l11 11a.5.5 0 0 0 .708-.708l-11-11a.5.5 0 0 0-.708 0Z"
              />
            </svg>
          </button>

          <ul className={classes.navigationMenuItem}>
            <li>
              <p
                onClick={() => {
                  navigateTo("about_about");
                }}
              >
                About
              </p>
            </li>

            <li>
              <p
                onClick={() => {
                  navigateTo("meet_the_team");
                }}
              >
                Team
              </p>
            </li>
            {/* <li>
              <p
                onClick={() => {
                  navigateTo("about_about");
                }}
              >
                Rollout Plan
              </p>
            </li> */}
            <li>
              <p
                onClick={() => {
                  navigateTo("FAQ");
                }}
              >
                FAQ
              </p>
            </li>
          </ul>
        </div>
        <div className={classes.sideBarSocials}>
          <ul className={classes.navigationMenuIcon}>
            <NavigationMenuIcon
              link="https://opensea.io/collection/morans"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16.711"
                height="16.109"
                viewBox="0 0 24.711 25.109"
              >
                <path
                  id="Union_1"
                  data-name="Union 1"
                  d="M5.8,25.11a8.638,8.638,0,0,1-3.95-2.2,6.224,6.224,0,0,1-1.59-3.193,4.359,4.359,0,0,1-.191-2.3h5.99a4.334,4.334,0,0,0,.576,1.869A2.12,2.12,0,0,0,8,20.017h3.108v-2.6H7.854a18.37,18.37,0,0,0,2.417-4.8c.61-2.264-.486-6.412-.486-6.412L8,1.771l3.108.989V.929s.475-1.012,1.124-.923,1.111.923,1.111.923V3.41a17.277,17.277,0,0,1,3.913,4.255,6.769,6.769,0,0,1,1.2,4.549,8.574,8.574,0,0,1-1.878,3.7c-1.615,1.984-1.392,1.5-1.392,1.5H13.412v2.6a15.383,15.383,0,0,0,3,0c.287-.194,2.054-2.088,2.054-2.088l6.249-2.015v1.759a6.784,6.784,0,0,0-2.364,1.612c-.843,1.014-1.495,3.2-2.525,4.36a5,5,0,0,1-2.1,1.466ZM1.355,14.515,6.8,4.868a15.023,15.023,0,0,1,1.609,5.311,7.838,7.838,0,0,1-1.284,4.336Z"
                  transform="translate(0 0)"
                  fill="currentColor"
                />
              </svg>
            </NavigationMenuIcon>
            <NavigationMenuIcon
              link="https://twitter.com/MoransNFT"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-twitter"
                viewBox="0 0 16 16"
              >
                <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
              </svg>
            </NavigationMenuIcon>
            {/* <NavigationMenuIcon
              link="https://discord.gg/yC5Kser3Wb"
              target="_blank"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                className="bi bi-discord"
                viewBox="0 0 16 16"
              >
                <path d="M13.545 2.907a13.227 13.227 0 0 0-3.257-1.011.05.05 0 0 0-.052.025c-.141.25-.297.577-.406.833a12.19 12.19 0 0 0-3.658 0 8.258 8.258 0 0 0-.412-.833.051.051 0 0 0-.052-.025c-1.125.194-2.22.534-3.257 1.011a.041.041 0 0 0-.021.018C.356 6.024-.213 9.047.066 12.032c.001.014.01.028.021.037a13.276 13.276 0 0 0 3.995 2.02.05.05 0 0 0 .056-.019c.308-.42.582-.863.818-1.329a.05.05 0 0 0-.01-.059.051.051 0 0 0-.018-.011 8.875 8.875 0 0 1-1.248-.595.05.05 0 0 1-.02-.066.051.051 0 0 1 .015-.019c.084-.063.168-.129.248-.195a.05.05 0 0 1 .051-.007c2.619 1.196 5.454 1.196 8.041 0a.052.052 0 0 1 .053.007c.08.066.164.132.248.195a.051.051 0 0 1-.004.085 8.254 8.254 0 0 1-1.249.594.05.05 0 0 0-.03.03.052.052 0 0 0 .003.041c.24.465.515.909.817 1.329a.05.05 0 0 0 .056.019 13.235 13.235 0 0 0 4.001-2.02.049.049 0 0 0 .021-.037c.334-3.451-.559-6.449-2.366-9.106a.034.034 0 0 0-.02-.019Zm-8.198 7.307c-.789 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.45.73 1.438 1.613 0 .888-.637 1.612-1.438 1.612Zm5.316 0c-.788 0-1.438-.724-1.438-1.612 0-.889.637-1.613 1.438-1.613.807 0 1.451.73 1.438 1.613 0 .888-.631 1.612-1.438 1.612Z" />
              </svg>
            </NavigationMenuIcon> */}
          </ul>
        </div>
      </React.Fragment>
    );
}


const mapDispatchToProps = (dispatch) => {
    return {
        onToggleSideDrawer:()=>dispatch(actions.sideDrawerToggle())
    }
}

export default connect(null, mapDispatchToProps)(SideBar);