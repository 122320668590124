import classes from "../scss/ImageGrid/about.module.scss";

const About = () => {
    return (
      <div className={classes.about} id="about_about">
        <h1>About</h1>
        <p>
          Morans are a creation of{" "}
          <a href="http://ocluse.com/" target="_blank" rel="noreferrer">
            Ocluse
          </a>
          , a Nairobi based team of creatives,enthusiastic about everything
          art,development and web3. The collection consists of 8888 morans, each
          with unique traits and attributes carefully crafted and moulded by these three
          morons.
          {/* The project's name was born from a community that believes in
          the strength, courage and endurance of its warriors and as such we
          intend to build nothing but a similarly structured community. */}
        </p>
      </div>
    );
}
export default About;