import classes from "../scss/navigationBar/navigationMenuItems.module.scss";
import NavigationMenuItem from "./navigationMenuItem";

const NavigationMenuItems = () => {
  return (
    <nav className={classes.navigationMenuItems}>
      <ul>
        <NavigationMenuItem
          divToNavigate="about_about"
          // link="/somethingToBeSetLater"
        >
          About
        </NavigationMenuItem>
        <NavigationMenuItem
          // link="/somethingToBeSetLater"
          divToNavigate="meet_the_team"
        >
          Team
        </NavigationMenuItem>
        {/* <NavigationMenuItem
        // link="/somethingToBeSetLater"
        >
          Rollout Plan
        </NavigationMenuItem> */}
        <NavigationMenuItem
          // link="/somethingToBeSetLater"
          divToNavigate="FAQ"
        >
          FAQ
        </NavigationMenuItem>
      </ul>
    </nav>
  );
};
export default NavigationMenuItems;
